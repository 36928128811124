import React from "react";
import { Col, Row } from "react-bootstrap";
import classNames from "classnames";

export interface ContentBoxProps extends React.HTMLAttributes<HTMLElement> {
  /**
   * Titel für die Content-Box
   */
  title: string;
  children: React.ReactNode;
}

/**
 * Box mit einem weißen Hintergrund und einer Linie am oberen Rand um die Box abzugrenzen
 */
export const ContentBox: React.FC<ContentBoxProps> = ({
  title,
  children,
  className,
  ...props
}: ContentBoxProps) => {
  return (
    <div
      className={classNames(
        className,
        "my-3",
        "p-3",
        "bg-white",
        "border-top",
        "border-secondary"
      )}
      {...props}
    >
      <Row className="mt-0">
        <Col>
          <h4 className="mt-4">{title}</h4>
        </Col>
      </Row>
      {children}
    </div>
  );
};
