import React from "react";
import { Button, Card, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

import classNames from "classnames/bind";

import styles from "./Tile.module.scss";
export interface TileProps {
  title: string;
  toPath: string;
  iconPath: string;
  onclick?: React.MouseEventHandler<HTMLElement>|undefined;
}

const cx = classNames.bind(styles);
const className = cx({
  tile_action: true,
  "t-1": true,
});

export const Tile: React.FC<TileProps> = ({ title, toPath, iconPath, onclick }: TileProps) => {
  return (
    <Col md={6} lg={4} xl={3} className="mt-3">
      <Card className="text-center border-light h-100">
        <Card.Body className="d-flex flex-column justify-content-between">
          <Card.Title className="text-uppercase" style={{ fontSize: "1.25rem" }}>
            {title}
          </Card.Title>
          <Card.Text>
            <img src={iconPath} className="mt-4 mb-4" width={60} height={60} />
            <Button variant="outline-primary" className={className} as={Link} to={toPath} onClick={onclick} >
              Starten
            </Button>
          </Card.Text>
        </Card.Body>
      </Card>
    </Col>
  );
};
