import { FieldProps } from "formik";
import { format, parseISO } from "date-fns";
import React from "react";

export const FormikDateField: React.FC<FieldProps<Date | "">> = (props: FieldProps<Date | "">) => {
  const { field, form: _formIgnored, meta, ...restProps } = props;
  const { value, ...fieldProps } = field;
  const parsedDate = typeof value == "string" ? parseISO(value) : value;
  const formattedDateString = value === undefined || value === "" ? "" : format(parsedDate, "yyyy-MM-dd");

  const { value: _metaValueIgnored, ...metaProps } = meta ?? { value: {} };
  restProps["value"] = formattedDateString;

  return <input type="date" value={formattedDateString} {...fieldProps} {...metaProps} {...restProps} />;
};

export const FormikDateTimeField: React.FC<FieldProps<Date | "">> = (props: FieldProps<Date | "">) => {
  const { field, form: _formIgnored, meta, ...restProps } = props;
  const { value, ...fieldProps } = field;
  const parsedDate = typeof value == "string" ? parseISO(value) : value;
  const formattedDateString = value === undefined || value === "" ? "" : format(parsedDate, "yyyy-MM-ddTHH:mm");

  const { value: _metaValueIgnored, ...metaProps } = meta ?? { value: {} };
  restProps["value"] = formattedDateString;

  return <input type="datetime-local" value={formattedDateString} {...fieldProps} {...metaProps} {...restProps} />;
};
