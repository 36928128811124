import React, { useState, useCallback, ReactNode, ReactElement } from "react";

import { Notification, Toast } from "./Notification";

export interface NotificationObject {
  message: string;
  status: string;
  type: NotificationType;
}

export enum NotificationType {
  Alert,
  Toast,
}

export interface NotificationContextProps {
  addNotification(
    message: string,
    status: string,
    type?: NotificationType
  ): void;
  removeNotification(): void;
  removeToast(id: string): void;
}

export interface NotificationProviderProps {
  children: ReactNode;
}

function generateUEID() {
  const firstNumber = (Math.random() * 46656) | 0;
  const secondNumber = (Math.random() * 46656) | 0;
  const first = ("000" + firstNumber.toString(36)).slice(-3);
  const second = ("000" + secondNumber.toString(36)).slice(-3);
  return first + second;
}

export const NotificationContext =
  React.createContext<NotificationContextProps>({
    addNotification: () => void 0,
    removeNotification: () => void 0,
    removeToast: () => void 0,
  });

export const NotificationProvider = (
  props: NotificationProviderProps
): ReactElement => {
  const [notification, setNotification] = useState<NotificationObject | null>(
    null
  );

  const [toasts, setToasts] = useState<Array<Toast>>([]);

  const removeNotification = () => setNotification(null);
  const removeToast = (id: string) => {
    setToasts((prev) => prev.filter((t) => t.id !== id));
  };

  const { children } = props;

  const addNotification = (
    message: string,
    status: string,
    type: NotificationType
  ) => {
    if (type === NotificationType.Alert)
      setNotification({ message, status, type });
    else
      setToasts((prev) => [
        ...prev,
        { id: generateUEID(), message, status, type, time: new Date() },
      ]);
  };

  const contextValue: NotificationContextProps = {
    addNotification: useCallback(
      (message, status, type = NotificationType.Toast) =>
        addNotification(message, status, type),
      []
    ),
    removeNotification: useCallback(() => removeNotification(), []),
    removeToast: useCallback((id) => removeToast(id), []),
  };

  return (
    <NotificationContext.Provider value={contextValue}>
      <Notification notification={notification} toasts={toasts} />
      {children}
    </NotificationContext.Provider>
  );
};
