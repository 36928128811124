import { useField, useFormikContext } from "formik";
import * as React from "react";
import {
  FormControl,
  FormControlProps,
  FormGroup,
  FormLabel,
  InputGroup,
} from "react-bootstrap";
import { ReplaceProps } from "react-bootstrap/helpers";

interface FormikControlBaseProps extends FormControlProps {
  name: string;
  label?: string | JSX.Element;
  noFormGroup?: boolean;
  children?: React.ReactNode;
  hasInputGroup?: boolean;
  inputGroupPrepend?: string | JSX.Element;
  noLabel?: boolean;
}

type FormikControlProps<As extends React.ElementType = "input"> = {
  as?: As;
} & ReplaceProps<As, FormikControlBaseProps>;

export function fieldToFormControl({
  noFormGroup: _noFormGroup,
  children: _children,
  label: _label,
  disabled,
  inputGroupPrepend: _inputGroupPrepend,
  hasInputGroup: _hasInputGroup,
  name,
  ...props
}: FormikControlBaseProps): FormControlProps {
  const { isSubmitting } = useFormikContext();
  const [field, meta] = useField(name);

  const fieldError = meta.error;
  const showError = meta.touched && !!fieldError;

  return {
    ...field,
    ...props,
    isInvalid: showError,
    disabled: disabled ?? isSubmitting,
  };
}

export function fieldToFeedBack({
  name,
}: FormikControlBaseProps): string | undefined {
  const [, { error }] = useField(name);
  return error;
}

export const FormikControl = <As extends React.ElementType = "input">(
  props: FormikControlProps<As>
): JSX.Element => {
  const ctrl = (
    <FormControl as={props.as} {...fieldToFormControl(props)}>
      {props.children}
    </FormControl>
  );

  const Core = (
    <>
      {!props.noLabel && <FormLabel>{props.label}</FormLabel>}

      {props.hasInputGroup ? (
        <InputGroup>
          <InputGroup.Prepend>
            <InputGroup.Text>{props.inputGroupPrepend}</InputGroup.Text>
          </InputGroup.Prepend>
          {ctrl}
        </InputGroup>
      ) : (
        ctrl
      )}

      <FormControl.Feedback type="invalid">
        {fieldToFeedBack(props)}
      </FormControl.Feedback>
    </>
  );

  return props.noFormGroup ? Core : <FormGroup>{Core}</FormGroup>;
};
