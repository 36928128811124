import { useField, useFormikContext } from "formik";
import React from "react";
import { Form, FormControl, FormGroup } from "react-bootstrap";
import {
  Typeahead,
  TypeaheadProps,
  TypeaheadModel,
} from "react-bootstrap-typeahead";

interface Props<T extends TypeaheadModel> extends TypeaheadProps<T> {
  name: string;
  label: string | JSX.Element;
  disabled?: boolean;
  noFormGroup?: boolean;
  showClear?: boolean;
  idField?: keyof T;
}

export const FormikTypeahead = <T extends TypeaheadModel>({
  name,
  label,
  disabled,
  options,
  noFormGroup,
  onChange,
  showClear = false,
  idField,
  ...props
}: Props<T>): JSX.Element => {
  const { isSubmitting } = useFormikContext();
  const [field, meta, helper] = useField(name);

  const fieldError = meta.error;
  const showError = meta.touched && !!fieldError;
  const fieldObj = options.find(
    (item) => (idField ? item[idField] : item) == field.value
  );
  const selected = fieldObj ? [fieldObj] : [];

  const core = (
    <>
      <Form.Label>{label}</Form.Label>
      <Typeahead
        id={`${field.name}-ta`}
        placeholder="Bitte auswählen ..."
        options={options}
        selected={selected}
        clearButton={showClear}
        {...props}
        isInvalid={showError}
        disabled={disabled ?? isSubmitting}
        className={showError ? "is-invalid" : undefined}
        onBlur={() => helper.setTouched(true)}
        onChange={(selected) => {
          if (selected.length === 1)
            helper.setValue(idField ? selected[0][idField] : selected[0]);
          else helper.setValue(null);

          if (onChange) onChange(selected);
        }}
      />

      <FormControl.Feedback type="invalid">{meta.error}</FormControl.Feedback>
    </>
  );

  return noFormGroup ? core : <FormGroup>{core}</FormGroup>;
};
