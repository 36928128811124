import React, { ReactNode } from "react";
import { Container } from "react-bootstrap";
import classNames from "classnames";

import styles from "./Footer.module.scss";

export const FooterListClassname = styles.links;
export interface FooterProps {
  /**
   * Versionsnummer der Anwendung
   */
  version?: string;
  children?: ReactNode;
  /**
   * Jahresangabe für das Copyright
   */
  year?: string;
}

/**
 * Fußzeile der Anwendung
 */
export const Footer: React.FC<FooterProps> = ({
  year = new Date().getFullYear(),
  version,
  children,
}) => {
  const versionText: string | undefined = version && `Version ${version}`;
  const copyrightText = `© ${year} Copyright: GELSENWASSER AG`;
  const hasChildren = !!children;

  const leftSide = hasChildren
    ? copyrightText + " | " + versionText
    : copyrightText;
  const rightSide = hasChildren ? children : versionText;

  return (
    <footer
      className={classNames(styles.footer, " bg-dark small text-secondary")}
    >
      <Container className="mt-sm-0 mb-2 mb-sm-0 d-flex justify-content-between align-items-center h-100">
        <div>{leftSide}</div>
        <div>{rightSide}</div>
      </Container>
    </footer>
  );
};
