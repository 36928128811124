import React from "react";
import { Button, ButtonProps } from "react-bootstrap";
import { IconType } from "react-icons/lib";
import { Link } from "react-router-dom";

type ListActionButtonProps = {
  to?: string;
  onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  icon: IconType;
} & Omit<
  ButtonProps,
  "children" | "variant" | "size" | "className" | "to" | "as"
>;

export const ListActionButton: React.VFC<ListActionButtonProps> = ({
  to,
  onClick,
  icon: Icon,
  ...rest
}: ListActionButtonProps) => {
  const linkProps = to ? { to: to, as: Link } : { to: "" };

  return (
    <Button
      {...rest}
      variant="listaction"
      size="sm"
      className="mr-1 rounded-circle"
      {...linkProps}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      <Icon />
    </Button>
  );
};
