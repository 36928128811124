import React, { ReactNode } from "react";
import { Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "../../assets/logo-gw.svg";
import classNames from "classnames";

const logoHeight = "30px";

export interface HeaderProps {
  /**
   * Titel der Anwendung, entweder als Text oder auch als JSX um zB Formatierungen zu ermöglichen
   * */
  title: string | ReactNode;
  /**
   * Optionaler Link, zu dem navigiert werden soll, wenn man auf den Titel klickt. Es muss dabei ein lokaler Link sein. Wird kein Link angegeben, dann wird auf "/" gezeigt.
   */
  titleLinkUrl?: string;
  /**
   * Optionale URL für ein Logo, welches neben dem Titel (also als Brand) angezeigt werden soll
   */
  titleLogoSrc?: string;
  /**
   * Soll kein Logo angezeigt werden?
   */
  noLogo?: boolean;
  /**
   * Optionale URL für ein Logo, welches angezeigt werden soll.
   */
  logoSrc?: string;
  /**
   * Soll das Logo *nicht* mit einem weißen Hintergrund versehen werden (weil das Logo zB transparent ist)?
   */
  noWhiteBackground?: boolean;
  children?: ReactNode;
}

/**
 * Kopfzeile der Anwendung
 */
export const Header: React.FC<HeaderProps> = ({
  title,
  titleLinkUrl = "/",
  titleLogoSrc,
  noLogo = false,
  noWhiteBackground = false,
  logoSrc,
  children,
}) => {
  const logoClasses = classNames(
    {
      "bg-white": !noWhiteBackground,
    },
    "p-1 d-none d-sm-inline"
  );
  const imgClasses = "d-inline-block align-top";

  return (
    <header>
      <Navbar expand="md" collapseOnSelect bg="gradient" variant="dark">
        <Navbar.Brand as={Link} to={titleLinkUrl} className="ml-2 mr-auto pr-2">
          {titleLogoSrc && (
            <>
              <img
                src={titleLogoSrc}
                height={logoHeight}
                className={imgClasses}
              />{" "}
            </>
          )}
          {title}
        </Navbar.Brand>
        {children && <Navbar.Toggle aria-controls="top-navbar" />}
        <Navbar.Collapse id="top-navbar" className="justify-content-end">
          {!noLogo && (
            <Navbar.Text className={logoClasses}>
              {logoSrc ? (
                <img src={logoSrc} height={logoHeight} />
              ) : (
                <Logo height={logoHeight} />
              )}
            </Navbar.Text>
          )}
          {children && <Nav>{children}</Nav>}
        </Navbar.Collapse>
      </Navbar>
    </header>
  );
};
